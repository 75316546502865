import React, { useEffect, useState } from 'react'

function DataTheme() {
    const [darkmode, setDarkmode] = useState(true)

    useEffect(() => {
        document.querySelector('html').setAttribute('data-theme', darkmode ? 'dark' : 'light')
        console.log(darkmode)
    }, [darkmode])

    return (
        <button className='data-theme' onClick={() => {setDarkmode(!darkmode); console.log(!darkmode)}}>Dark/Light</button>
    )
}

export default DataTheme