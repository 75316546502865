import React, { useState } from 'react'
import { Link } from "react-router-dom";

import style from './Authentication.module.css'
import WelcomeText from '../../components/text/WelcomeText';

function SignIn() {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const onContinue = (e) => {
        e.preventDefault();
    }

    const onContinueWithGoogle = (e) => {
        e.preventDefault();
    }

    return (
        <div className={style.AuthContainer}>
            <WelcomeText />
            <form className={style.Form} method='post'>
                <input
                    type="text"
                    name="login"
                    id="login"
                    placeholder='Email address or username'
                    value={login}
                    onChange={(e) => {setLogin(e.target.value)}}
                    className={style.FormInput}
                />
                <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder='••••••••'
                    value={password}
                    onChange={(e) => {setPassword(e.target.value)}}
                    className={style.FormInput}
                />
                <button onClick={onContinue} className={[style.FormInput, style.Continue].join(' ')}>Continue</button>
                <div className={style.Hint}>Don't have account? <Link to="/signup">Sign Up</Link></div>
                <div className={style.Or}>
                    <span>OR</span>
                </div>
                <button onClick={onContinueWithGoogle} className={[style.FormInput, style.ContinueWithGoogle].join(' ')}>Continue with Google</button>
            </form>
        </div>
    )
}

export default SignIn