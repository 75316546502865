import React from 'react'

import style from './ProfilePicturePreview.module.css'

function ProfilePicturePreview({pictureURL, alt, size, additionalStyle}) {
  return (
    <div className={style.ProfilePicturePreview} style={{width: size, height: size, ...additionalStyle}}>
        <img src={pictureURL} alt={alt} />
    </div>
  )
}

export default ProfilePicturePreview