import ProjectModel from "../models/project/ProjectModel";

export default class ProjectController {
    model: ProjectModel;

    constructor(projectModel: ProjectModel) {
        this.model = projectModel;
    }

    getProject(id: string) {
        console.log(`Getting project ${id}`);
        return this.model.getProject(id);
    }
    getProjects(arrayOfID: Array<string>) {
        console.log(`Getting projects ${arrayOfID}`);
        return this.model.getProjects(arrayOfID);
    }
    updateProject(projectData: Object) {
        console.log(`Updating project`);
        return this.model.updateProject(projectData);
    }
    deleteProject(id: string) {
        console.log(`Deleting project ${id}`);
        return this.model.deleteProject(id);
    }
}