import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/home/Home';
import SignIn from './views/authentication/SignIn';
import SignUp from './views/authentication/SignUp';

import './index.css'
import DataTheme from './components/DataTheme';
import Explore from './views/explore/Explore';
import ProjectPage from './views/project/ProjectPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path='/'
          element={<Home />}
        />
        <Route 
          path='/explore'
          element={<Explore />}
        />
        <Route 
          path='/signin'
          element={<SignIn />}
        />
        <Route 
          path='/signup'
          element={<SignUp />}
        />
        <Route 
          path='/project/:projectID'
          element={<ProjectPage />}
        />
      </Routes>
      <DataTheme />
    </BrowserRouter>
  );
}

export default App;
