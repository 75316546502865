import React from 'react'

import style from './Project.module.css'
import SkillPreviewItem from './SkillPreviewItem'
import ProfilePicturePreview from '../profile/ProfilePicturePreview'

function OpeningBlock({title, skillSet=[], openingContributors=[]}) {
  return (
    <div className={style.OpeningBlock}>
        <div className={style.OpeningContainer}>
            <h3 className={style.OpeningTitle}>{title}</h3>
            <ul className={style.OpeningSkillSet}>
                {skillSet.map((skillName) => (
                    <SkillPreviewItem>{skillName}</SkillPreviewItem>
                ))}
            </ul>
        </div>
        <div className={style.OpeningInfo}>
            <div className={style.OpeningContributorsContainer}>
                {
                    openingContributors.length > 0 ? (
                        <>
                            <h4>Contributors:</h4>
                            <div className={style.OpeningContributorsList}>
                                {openingContributors.map((openingContributor, index) => index < 5 && (
                                    <ProfilePicturePreview
                                        pictureURL={openingContributor.profilePictureURL}
                                        size={'32pt'}
                                        additionalStyle={{marginRight: "-10px"}}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <h4>Become first contributor</h4>
                        </>
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default OpeningBlock