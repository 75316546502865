import React from 'react'
import style from './ProjectPreview.module.css'

function CreateProject() {
  return (
    <div className={style.CreateProjectBlock}>
        <div className={style.CreateProjectText}>Create your project</div>
    </div>
  )
}

export default CreateProject