import AuthenticationModel from "../models/authentication/AuthenticationModel";

export default class AuthenticationController {
    model: AuthenticationModel

    constructor(authModel: AuthenticationModel) {
        this.model = authModel;
    }

    signInWithEmail() {
        console.log("Signing In with Email");
        return this.model.signInWithEmail();
    }
    signInWithGoogle() {
        console.log("Signing In with Google");
        return this.model.signInWithGoogle();
    }
    signUp() {
        console.log("Signing Up");
        return this.model.signUp();
    }
    signOut() {
        console.log("Signing Out");
        return this.model.signOut();
    }
}