import AuthenticationModel from './AuthenticationModel.ts';

export default class FirebaseAuthenticationModel extends AuthenticationModel {
    signInWithEmail() {
        throw new Error("Method not implemented.");
    }
    signInWithGoogle() {
        throw new Error("Method not implemented.");
    }
    signUp() {
        throw new Error("Method not implemented.");
    }
    signOut() {
        throw new Error("Method not implemented.");
    }

}