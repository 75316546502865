import React from 'react'
import style from './ProjectPreview.module.css'
import SkillPreviewItem from './SkillPreviewItem'

export default function ProjectPreview({backgroundURL, pictureURL, name, skillList=[]}) {
    return (
        <div className={style.ProjectBlock}>
            <div className={style.BackgroundBlock}>
                <img src={backgroundURL} alt="" className={style.Background}/>
            </div>
            <div className={style.PictureBlock}>
                <img src={pictureURL} alt="" className={style.Picture} />
            </div>
            <h3 className={style.ProjectName}>{name}</h3>
            <ul className={style.SkillList}>
                {skillList.map((skillName) => (
                    <SkillPreviewItem>{skillName}</SkillPreviewItem>
                ))}
            </ul>
        </div>
    )
}
