import React, { useState } from 'react'
import style from './Explore.module.css'
import ProjectPreview from '../../components/project/ProjectPreview'
import CreateProject from '../../components/project/CreateProject'

function Explore() {
    const [projectList, setProjectList] = useState([
        {
            id: 1,
            name: "Ambitious creators EST. 24",
            backgroundURL: "https://images.unsplash.com/photo-1494232410401-ad00d5433cfa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            pictureURL: "https://images.unsplash.com/photo-1473646590311-c48e1bc77b44?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            skillList: ['Acoustic guitar', 'Electric guitar', 'FL studio', 'SFX', 'Lyrics writer', 'Ableton']
        }
    ]);

    return (
        <div className={style.ExploreContainer}>
            <form className={style.SearchForm} method='post'>
                <input
                    type="text"
                    placeholder='Search for project...'
                    className={style.SearchInput}    
                />
            </form>
            <div className={style.ProjectsGrid}>
                {projectList.map((project) => (
                    <ProjectPreview
                        name={project.name}
                        pictureURL={project.pictureURL}
                        backgroundURL={project.backgroundURL}
                        skillList={project.skillList}
                    />
                ))}
                <CreateProject />
            </div>
        </div>
    )
}

export default Explore