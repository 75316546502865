import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import style from './ProjectPage.module.css'
import { projectController } from '../../config/controllers.ts';
import ProfilePicturePreview from '../../components/profile/ProfilePicturePreview.jsx';
import OpeningBlock from '../../components/project/OpeningBlock.jsx';

function ProjectPage() {
    const { projectID } = useParams();
    const [projectData, setProjectData] = useState({
        name: "Ambitious creators EST. 24",
        backgroundURL: "https://images.unsplash.com/photo-1494232410401-ad00d5433cfa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        pictureURL: "https://images.unsplash.com/photo-1473646590311-c48e1bc77b44?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit alias, nemo voluptatem repudiandae ex, similique nostrum laudantium illum nam, aspernatur eos quisquam. Porro in reiciendis accusamus voluptates itaque, dolorum quam qui esse. Eaque omnis maiores doloremque iste, nemo aspernatur, tenetur veritatis est accusamus dolorum commodi sed reiciendis architecto nostrum? Suscipit aliquam accusamus pariatur nihil itaque repellat assumenda praesentium unde voluptate non doloremque dolorum debitis velit cum eos, obcaecati culpa temporibus reprehenderit ipsum dicta, reiciendis saepe sunt facilis voluptatum. Nulla temporibus quia atque distinctio beatae culpa, optio iure, tempora eos minima quae repellendus hic harum ut deleniti ipsum sunt natus dolores, minus nesciunt eveniet ipsa. Repudiandae unde eum sequi ipsum nobis eveniet! Quis corporis molestiae ullam pariatur nam doloribus libero deserunt nostrum dolor vero, doloremque quisquam blanditiis, molestias perspiciatis consequuntur ipsum numquam magni earum? Officiis tempora, dolorum recusandae quidem non, debitis reprehenderit voluptate omnis nulla laborum reiciendis. At perspiciatis, quas a perferendis eius nemo culpa deserunt ab architecto blanditiis atque hic rerum distinctio fugit illo magni libero natus, vel unde vitae voluptatum! Facilis cupiditate, provident voluptates hic beatae, omnis expedita explicabo nostrum et architecto cum earum illum voluptatum, commodi ipsa iste doloribus quis possimus facere eaque mollitia. Quo, dolorum officiis. Fugiat!",
        contributors: [
            {username: "alice.thompson", profilePictureURL: "https://images.unsplash.com/photo-1550703703-c6f229024ba9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
            {username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"},
        ],
        openings: [
            {title: "Acoustic guitar player", skillSet: ["Acoustic guitar", "FL studio"], openingContributors: [{username: "bernard.crook", profilePictureURL: "https://images.unsplash.com/photo-1529111290557-82f6d5c6cf85?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}, {username: "alice.thompson", profilePictureURL: "https://images.unsplash.com/photo-1550703703-c6f229024ba9?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}]},
            {title: "Acoustic guitar player", skillSet: ["Acoustic guitar", "FL studio"]},
            {title: "Acoustic guitar player", skillSet: ["Acoustic guitar", "FL studio"]},
            {title: "Acoustic guitar player", skillSet: ["Acoustic guitar", "FL studio"]},
        ]
    });

    useEffect(() => {
        // projectController.getProject(projectID);
    }, []);

    return (
        <div>
            <header className={style.HeaderContainer}>
                <div className={style.HeaderProjectDescription}>
                    <p>
                        {projectData.description}
                    </p>
                </div>
                <div className={style.HeaderProjectPresentation}>
                    <div className={style.BackgroundBlock}>
                        <img src={projectData.backgroundURL} className={style.Background}/>
                    </div>
                    <div className={style.PictureBlock}>
                        <img src={projectData.pictureURL} className={style.Picture} />
                    </div>
                    <h1 className={style.ProjectName}>{projectData.name}</h1>
                    <div>
                        <span className="placeholder-color">{window.location.hostname}/project/</span>{projectID}
                    </div>
                </div>
                <div className={style.HeaderProjectContributors}>
                    <h3>Contributors:</h3>
                    <div className={style.ContributorsGrid}>
                        {projectData.contributors.map((contributor, index) => index < 8 && (
                            <ProfilePicturePreview
                                pictureURL={contributor.profilePictureURL}
                                alt={contributor.username}
                            />
                        ))}
                    </div>
                </div>
            </header>
            <main className={style.ProjectMainContainer}>
                <nav className={style.ProjectNavigation}>
                    <ul className={style.ProjectNavigationList}>
                        <li className={style.ProjectNavigationItem}>Openings</li>
                        <li className={style.ProjectNavigationItem}>Proposals</li>
                    </ul>
                </nav>
                <div className={style.OpeningsGrid}>
                    {projectData.openings.map((opening) => (
                        <OpeningBlock
                            title={opening.title}
                            skillSet={opening.skillSet}
                            openingContributors={opening.openingContributors}
                        />
                    ))}
                </div>
            </main>
        </div>
    )
}

export default ProjectPage