import ProjectModel from "./ProjectModel.ts";

export default class FirebaseProjectModel extends ProjectModel {
    getProject(id: string) {
        throw new Error("Method not implemented.");
    }
    getProjects(arrayOfID: Array<string>) {
        throw new Error("Method not implemented.");
    }
    updateProject(projectData: Object) {
        throw new Error("Method not implemented.");
    }
    deleteProject(id: string) {
        throw new Error("Method not implemented.");
    }

}